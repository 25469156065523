<template>
    <!-- Preloader Start -->
    <div id="preloader" class="bg-primary">
        <div class="preloader--spinner"></div>
    </div>
    <!-- Preloader End -->
    <!-- Wrapper Start -->
    <div class="wrapper">
        <!-- Header Section Start -->
        <header id="header" class="header--section">
            <!-- Header Navbar Start -->

            <nav class="header--navbar navbar">
                <div class="container">
                    <div class="navbar-header">
                        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                            data-target="#headerNav">
                            <span class="sr-only">Toggle Navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                        <!-- Logo Start -->
                        <!-- <a href="index.html" class="navbar-brand">
                      <span class="vc--parent">
                          <span class="vc--child">
                              <img src="img/logo.png" alt="">
                          </span>
                      </span>
                  </a> -->
                        <!-- Logo End -->
                    </div>
                    <div id="headerNav" class="navbar-collapse collapse">
                        <!-- Header Nav Links Start -->

                        <ul class="header--nav-links ff--primary nav navbar-right">
                            <li :class="{'active': activeMenuIndex == 0}"><router-link :to="{ name: 'index', hash: '#header' }" @click="activeMenuIndex=0">Home</router-link></li>
                            <li :class="{'active': activeMenuIndex == 1}"><router-link :to="{ name: 'index', hash: '#aboutme' }" @click="activeMenuIndex=1">About Me</router-link></li>
                            <!-- <li><a href="#services" data-trigger="scroll">Services</a></li> -->
                            <li :class="{'active': activeMenuIndex == 2}"><router-link :to="{ name: 'index', hash: '#skills' }" @click="activeMenuIndex = 2">Skills</router-link></li>
                            <!-- <li><a href="#portfolio" data-trigger="scroll">Portfolio</a></li> -->
                            <li :class="{'active': activeMenuIndex == 3}"><router-link :to="{name:'blog'}" @click="activeMenuIndex = 3">Blog</router-link></li>
                            <li :class="{'active': activeMenuIndex == 4}"><router-link :to="{ name: 'index', hash: '#contact' }" @click="activeMenuIndex = 4">Contact</router-link></li>
                            <!-- <li class="dropdown">
                      <a href="#" class="dropdown-toggle" data-toggle="dropdown">Pages<span class="caret"></span></a>
                      <ul class="dropdown-menu">
                          <li><a href="index.html">Home Page</a></li>
                          <li><a href="blog.html">Blog Page</a></li>
                          <li><a href="blog-details.html">Blog Details Page</a></li>
                          <li><a href="contact.html">Contact Page</a></li>
                          <li><a href="404.html">404 Page</a></li>
                          <li><a href="coming-soon.html">Coming Soon Page</a></li>
                      </ul>
                  </li> -->
                        </ul>
                        <!-- Header Nav Links End -->
                    </div>
                </div>
            </nav>


            <!-- Header Navbar End -->
        </header>
        <!-- Header Section End -->
        <router-view></router-view>
        <!-- Footer Section Start -->
        <footer class="footer--section pt--100 pb--100 text-center">
            <div class="container">
                <!-- Footer Social Start -->
                <div class="footer--social">
                    <ul class="nav" data-scroll-reveal="group">
                        <li><a href="https://twitter.com/twsbg"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/in/nikolay-chankov/"><i class="fa fa-linkedin"></i></a></li>
                    </ul>
                </div>
                <!-- Footer Social End -->
                <!-- Copyright Text Start -->
                <p class="copyright--text fs--16 ff--primary">Copyright &copy; 2023 <a href="#">Nikolay Chankov</a>. All
                    Rights Reserved.</p>
                <!-- Copyright Text End -->
            </div>
        </footer>
        <!-- Footer Section End -->
    </div>
    <!-- Wrapper End -->

    <!-- Back To Top Button Start -->
    <div id="backToTop">
        <a href="#" class="btn btn-default active"><i class="fa fa-long-arrow-up"></i></a>
    </div>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            activeMenuIndex: 0
        }
    },
    components: {

    },
    watch: {
        '$route': function () {
            setTimeout(() => {
                this.handleScroll();
            }, 300);
        }
    },
    methods: {
        handleScroll() {
            let id = this.$route.hash.replace('#', '');
            let e = document.getElementById(id);
            if(e == null) return;
            e.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
                inline: 'start'
            });
        }
    }
}
</script>
