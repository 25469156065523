<template>
            <BlogPost   v-if="!isFullPost"
                        :isFullPost="isFullPost"
                        :tags="postmeta.tags" 
                        :date="postmeta.date"
                        :image="postmeta.image"
                        :author="postmeta.author"
                        :routeName="postmeta.routeName"
                        :title="postmeta.name">
                <template #title>
                   
                </template>      
                <template #excerpt>                    
                    <p>
                        If you have bootstrap framework in the 3rd party libraries in your project then you probably do not want to touch the original sources but overwrite them. 
                        Here is how to do by compiling the less files.
                    </p>
                    <p>
                        When you download the bootstrap package along with the dist folder you have all the less files to compile it yourself.
                    </p>
                </template>       
            </BlogPost>
            <PostView  v-else
                        :isFullPost="isFullPost"
                        :tags="postmeta.tags" 
                        :date="postmeta.date"
                        :image="postmeta.image"
                        :author="postmeta.author"
                        :routeName="postmeta.routeName"
                        :title="postmeta.name">
                        <template #title>
                    <a href="#" class="btn-link"> {{ postmeta.name }} </a>
                </template>      
                <template #body>
                    <p>
                        If you have bootstrap framework in the 3rd party libraries in your project then you probably do not want to touch the original sources but overwrite them. 
                        Here is how to do by compiling the less files.
                    </p>
                    <p>
                        When you download the bootstrap package along with the dist folder you have all the less files to compile it yourself.
                    </p>
                    <img src="/assets/BootstrapCustomization-1.png" alt="Bootstrap Customization">         
                    <p>But again you do not want touching these sources as new versions will replace old ones and overwrite your changes.</p>
                    <p>Here is a good practice:</p>
                    <code>
                    /style <br>
                    |- bootstrap/ /*downloaded sources*/ <br>
                    |    |- less/ <br>
                    |    |- js/ <br>
                    |   ... <br>
                    |- shared/ /*your modifications*/ <br>
                    |- bootstrap.less    <br>
                    |- variables.less <br>
                </code>
                <br>
                <ol>
                    <li>Make a shared directory and copy the main bootstrap.less file.</li>
                    <li>Make your modifications in a new less file in the same shared directory. Include the original file you want to modify and then overwrite the variables you want.
                        <code>
                            /*<br>
                            *    /style/shared/variables.less<br>
                            */<br>
                            @import "../bootstrap-master/less/variables.less";<br>
                            @gridColumns: 16; // modification<br>
                        </code>
                    </li>
                    <li>Modify the bootstrap.less to include your shared less file/s with modifications.</li>
                    <li>Complie the modified bootstrap.less to bootstrap.css and use it</li>
                </ol>


                </template>     
            </PostView>
</template>

<script>
import BlogPost from '@/components/blog/BlogPost.vue';
import PostView from '@/views/PostView.vue';

const postmeta = {
        routeName: 'bootstrap-customization',        
        routePath: '/blog/bootstrap-customization',        
        name: 'Bootstrap: Customization without touching original sources',
        date: '2017-05-19',
        tags: ['Web'],
        categories: ['Development'],
        image: '/assets/BootstrapCustomization.jpg',
        author: 'Nikolay Chankov'
    };
export { postmeta };

export default {
    name: 'BootstrapCustomization',
    components: {
        BlogPost,
        PostView
    },
    computed: {
        postmeta() {
            return postmeta;
        }
    },
    data() {
        return {
            isFullPost: true
        }
    },
    mounted() {
        if(this.$route.name == postmeta.routeName)
            this.isFullPost = true;
        else
            this.isFullPost = false;
    }
}

</script>