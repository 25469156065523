<template>
            <BlogPost   v-if="!isFullPost"
                        :isFullPost="isFullPost"
                        :tags="postmeta.tags" 
                        :date="postmeta.date"
                        :image="postmeta.image"
                        :author="postmeta.author"
                        :routeName="postmeta.routeName"
                        :title="postmeta.name">
    
                <template #excerpt>
                    <quote>There are only two hard things in Computer Science: cache invalidation and naming
                                            things.</quote>
                                        <p> -- Phil Karlton</p>

                                        <p>
                                            Many developers many coding styles so it is important to set a naming convention on each
                                            project where you work in a team.
                                            There isn't wrong convention but there are many ways you can approach so agree on a pattern
                                            to make the code intuitive.
                                        </p>
                                        <p>
                                            Here is an example to take away and modify by your preferences (see below for explanations
                                            and examples):
                                        </p>
                </template>       
            </BlogPost>
            <PostView  v-else
                        :isFullPost="isFullPost"
                        :tags="postmeta.tags" 
                        :date="postmeta.date"
                        :image="postmeta.image"
                        :author="postmeta.author"
                        :routeName="postmeta.routeName"
                        :title="postmeta.name">
                        <template #title>
                    <a href="#" class="btn-link"> {{ postmeta.name }} </a>
                </template>      
                <template #body>
                    <quote>There are only two hard things in Computer Science: cache invalidation and naming
                                            things.</quote>
                                        <p> -- Phil Karlton</p>

                                        <p>
                                            Many developers many coding styles so it is important to set a naming convention on each
                                            project where you work in a team.
                                            There isn't wrong convention but there are many ways you can approach so agree on a pattern
                                            to make the code intuitive.
                                        </p>
                                        <p>
                                            Here is an example to take away and modify by your preferences (see below for explanations
                                            and examples):
                                        </p>

                                        <ol>
                                            <li>Locally declared variables: Camel case</li>
                                            <li>Method parameters: Camel case</li>
                                            <li>Method names: Pascal case. Try to use a verb-noun pair for example GetPath(). Methods with return values - The name should reflect the return value.</li>
                                            <li>Property names: Pascal case</li>
                                            <li>Class names: Pascal case</li>
                                            <li>Interface names: Pascal case starting with an I</li>
                                            <li>Private fields: Camel case</li>
                                            <li>Constants: Uppercase</li>
                                            <li>Private static fields: Camel case</li>
                                            <li>Custom exceptions: Suffix all custom exception names with Exception</li>
                                            <li>Delegates: Suffix all event handlers with Handler; suffix everything else with Delegate.</li>
                                            <li>Enums: Pascal Case with prefix En. Do not suffix with Enum. Try to explicitly specify the values of the enums.</li>
                                            <li>Curly brackets: use the after each control flow statement.</li>
                                        </ol>
                                      
    
                                        
                                        <b>Donts:</b>
                                        <p>Avoid using abbreviations</p>
                                        <p>Avoid undersores in identifiers except for prefix and when using Uppercase. Even then use only one in a row.</p>
                                        
                                        <b>Uppercase</b>
                                        <p>All letters in the identifier are capitalized.</p>
                                        <p>Example:</p>
                                    
                                           <code> MAX, PI, MIN_VAL </code>
                                           <p>   Separate words by underscore to avoid "Screaming Caps" which is not readable</p>
                                        <br>
                                        <b>Camel case</b>
                                        <p>The first letter of an identifier is lowercase and the first letter of each subsequent concatenated word is capitalized.</p>
                                        <p>Example:</p>
                                        <code>myOwnService, isValid</code>
                                        <br>       <br>
                                        <b>Pascal case</b>
                                        <p>The first letter in the identifier and the first letter of each subsequent concatenated word are capitalized.</p>
                                        <p>Example:</p>
                                        <code>DataObject, DateTime</code>
                                        

                </template>     
            </PostView>
</template>

<script>
import BlogPost from '@/components/blog/BlogPost.vue';
import PostView from '@/views/PostView.vue';

const postmeta = {
        routeName: 'CSharp-naming-convetions',        
        routePath: '/blog/CSharp-naming-convetions',        
        name: 'C# NAMING CONVENTIONS (GENERAL PURPOSE)',
        date: '2017-02-20',
        tags: ['CSharp', '.NET', 'Coding'],
        categories: ['Development'],
        image: '/assets/CodeNaming.jpg',
        author: 'Nikolay Chankov'
    };
export { postmeta };

export default {
    name: 'CSharpNamingConvetions',
    components: {
        BlogPost,
        PostView
    },
    computed: {
        postmeta() {
            return postmeta;
        }
    },
    data() {
        return {
            isFullPost: true
        }
    },
    mounted() {
        if(this.$route.name == postmeta.routeName)
            this.isFullPost = true;
        else
            this.isFullPost = false;
    }
}

</script>