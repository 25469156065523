<template>
    <div class="col-md-4 pb--60">

        <!-- Widget Start -->
        <div class="widget">
            <div class="widget--title">
                <h2 class="h4">Categories</h2>
            </div>

            <!-- Links Widget Start -->
            <div class="links--widget fs--16 ff--primary bg-default">
                <ul class="nav">
                    <li  v-for="cat in getAllCategories()" :key="cat"><router-link :to="'/blog?category='+cat">{{cat}}</router-link></li>
                </ul>
            </div>
            <!-- Links Widget End -->
        </div>
        <!-- Widget End -->

        <!-- Widget Start -->
        <div class="widget">
            <div class="widget--title">
                <h2 class="h4">Popular Tags</h2>
            </div>

            <!-- Links Widget Start -->
            <div class="tags--widget ff--primary bg-default">
                <ul class="nav">
                    <li v-for="tag in getAllTags()" :key="tag"><router-link :to="'/blog?tag='+tag">{{tag}}</router-link></li>
                </ul>
            </div>
            <!-- Links Widget End -->
        </div>
        <!-- Widget End -->
    </div>
</template>

<script>
import {routes} from '@/router.js';

export default {
    name: 'BlogSidebar',
    data() {
        return {

        }
    },
    components: {

    },
    methods: {
        getAllTags() {
            let tags = [];
            routes.forEach(route => {
                if (route.meta && route.meta.tags) {
                    tags = tags.concat(route.meta.tags);
                }
            });
            tags = tags.filter((value, index, self) => self.indexOf(value) === index);
            return tags;
        },
        getAllCategories() {
            let categories = [];
            routes.forEach(route => {
                if (route.meta && route.meta.categories) {
                    categories = categories.concat(route.meta.categories);
                }
            });
            categories = categories.filter((value, index, self) => self.indexOf(value) === index);
            return categories;
        }
    }
}

</script>