import { createWebHistory, createRouter } from 'vue-router'

import {postmeta as meta1 , default as post1} from '@/components/blog/posts/CSharpNamingConvetions'
import {postmeta as meta2 , default as post2} from '@/components/blog/posts/BootstrapCustomization'

const routes = [
    { path: '/', name: 'index', component: () => import('@/views/IndexView.vue')},
    { path: '/blog', name: 'blog', component: () => import('@/views/BlogView.vue') },
    { path: meta1.routePath, name: meta1.routeName , component: post1 , meta: meta1  },
    { path: meta2.routePath, name: meta2.routeName , component: post2 , meta: meta2  },
  ];

export {routes};

const router = createRouter({
    history: createWebHistory(),
    routes
});
  
export default router