<template>
    
          
                        <!-- Post Item Start -->
                        <div class="post--item">
                            <div class="post--header clearfix">
                                <!-- Post Date Start -->
                                <div class="post--date ff--primary bg-primary">
                                    <p>
                                        <a href="#">{{ moment(date) }}</a>                                     
                                    </p>
                                </div>
                                <!-- Post Date End -->

                                <!-- Post Title Start -->
                                <div class="post--title text-uppercase ov--h">
                                    <h3 class="h3 fw--400">
                                        <router-link :to="{name: routeName}" class="btn-link"> {{ title }} </router-link>
                                    </h3>
                                </div>
                                <!-- Post Title End -->
                            </div>

                            <!-- Post Image Start -->
                            <div class="post--img">
                                <a href="#"><img :src="image" alt=""></a>
                            </div>
                            <!-- Post Image End -->

                            <!-- Post Meta Start -->
                            <div class="post--meta ff--primary">
                                <ul class="nav">
                                    <li>
                                        <i class="fa fa-user"></i>
                                        <a href="#">{{author}}</a>                                  
                                    </li>
                                    <li>
                                        <i class="fa fa-folder-o"></i>
                                        <a v-for="tag in tags" :key="tag" href="#">{{tag}}</a>
                                    </li>

                                </ul>
                            </div>
                            <!-- Post Meta End -->

                            <!-- Post Excerpt Start -->
                            <div class="post--excerpt">
                                <slot name="excerpt"></slot>

                            </div>
                            <!-- Post Excerpt End -->

                            <!-- Post Action Start -->
                            <div v-if="!isFullPost" class="post--action text-uppercase">
                                <router-link :to="{name: routeName}" class="btn-link">Read More<i
                                        class="fa fa-long-arrow-right"></i></router-link>
                            </div>
                            <!-- Post Action End -->
                        </div>
                        <!-- Post Item End -->



            
</template>

<script>
// import moment from 'moment';
import moment from 'moment';

export default {
    name: 'BlogPost',
    props: {
        isFullPost: {
            type: Boolean,
            default: false
        },
        date: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        author: {
            type: String,
            default: ''
        },
        tags: {
            type: Array,
            default:  ()=>[]
        },
        routeName: {
            type: String,
            default: ''        
        },
        title: {
            type: String,
            default: ''        
        }
    },
    methods: {
        moment(date) {
            return moment(date).format('ll');
        }
    },
}
</script>