<template>
 <section id="blog" class="section pt--130 pb--40">
        <div class="container">
            <div class="row">
                <div class="col-md-8 pb--60">
           
            <div class="post--items">              
                <BlogPost   :isFullPost="isFullPost"
                            :tags="tags" 
                            :date="date"
                            :image="image"
                            :author="author"
                            :routeName="routeName"
                            :title="title">   
                <template #excerpt>
                    <slot name="body"></slot>
                </template>       
            </BlogPost>
            </div>

                </div>

                <BlogSidebar></BlogSidebar>
        </div>
    </div>
</section>
</template>


<script>

import BlogPost from '@/components/blog/BlogPost.vue';
import BlogSidebar from '@/components/blog/BlogSidebar.vue';

export default {
    name: 'PostView',
    components: {
        BlogPost,
        BlogSidebar
    },
    props: {
        isFullPost: {
            type: Boolean,
            default: false
        },
        date: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        author: {
            type: String,
            default: ''
        },
        tags: {
            type: Array,
            default:  ()=>[]
        },
        routeName: {
            type: String,
            default: ''        
        },
        title: {
            type: String,
            default: ''        
        }
    },
    data() {
        return {
      
        }
    },
}
</script>
